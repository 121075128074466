/* barlow-100 - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 100;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-100.eot'); /* IE9 Compat Modes */
    src: local('Barlow Thin'), local('Barlow-Thin'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-100.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-100.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-100italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 100;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-100italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow Thin Italic'), local('Barlow-ThinItalic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-100italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-100italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-200 - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 200;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-200.eot'); /* IE9 Compat Modes */
    src: local('Barlow ExtraLight'), local('Barlow-ExtraLight'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-200.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-200.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-200italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 200;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-200italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow ExtraLight Italic'), local('Barlow-ExtraLightItalic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-200italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-200italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-300 - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-300.eot'); /* IE9 Compat Modes */
    src: local('Barlow Light'), local('Barlow-Light'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-300.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-300.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-300italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-300italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow Light Italic'), local('Barlow-LightItalic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-300italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-300italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-regular - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-regular.eot'); /* IE9 Compat Modes */
    src: local('Barlow Regular'), local('Barlow-Regular'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-regular.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-regular.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow Italic'), local('Barlow-Italic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-500 - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-500.eot'); /* IE9 Compat Modes */
    src: local('Barlow Medium'), local('Barlow-Medium'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-500.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-500.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-500italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-500italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow Medium Italic'), local('Barlow-MediumItalic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-500italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-500italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-600 - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-600.eot'); /* IE9 Compat Modes */
    src: local('Barlow SemiBold'), local('Barlow-SemiBold'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-600.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-600.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-600italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-600italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow SemiBold Italic'), local('Barlow-SemiBoldItalic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-600italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-600italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-700 - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-700.eot'); /* IE9 Compat Modes */
    src: local('Barlow Bold'), local('Barlow-Bold'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-700.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-700.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-700italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-700italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-700italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-700italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-800 - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-800.eot'); /* IE9 Compat Modes */
    src: local('Barlow ExtraBold'), local('Barlow-ExtraBold'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-800.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-800.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-800italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 800;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-800italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow ExtraBold Italic'), local('Barlow-ExtraBoldItalic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-800italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-800italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-900 - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-900.eot'); /* IE9 Compat Modes */
    src: local('Barlow Black'), local('Barlow-Black'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-900.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-900.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-900italic - latin_vietnamese */
@font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 900;
    src: url('./assets/fonts/barlow-v4-latin_vietnamese-900italic.eot'); /* IE9 Compat Modes */
    src: local('Barlow Black Italic'), local('Barlow-BlackItalic'),
    url('./assets/fonts/barlow-v4-latin_vietnamese-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-v4-latin_vietnamese-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-900italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-v4-latin_vietnamese-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-v4-latin_vietnamese-900italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
