.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#root > .ion-page {

}

ion-header {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

ion-app {
  /*margin-top: calc(env(safe-area-inset-top) + var(--ion-statusbar-padding) - 30px);*/
  /*margin-bottom: calc(env(safe-area-inset-bottom) + var(--ion-statusbar-padding) - 20px);*/
}

